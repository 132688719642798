import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { ApiserviceService } from "../../../../src/app/apiservice.service";
import { environment } from "../../../../src/environments/environment";

@Component({
  selector: "app-admin-observer-adm-upload",
  templateUrl: "./admin-observer-adm-upload.component.html",
  styleUrls: ["./admin-observer-adm-upload.component.scss"],
})
export class AdminObserverAdmUploadComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @ViewChild("menuContainer") menuContainer!: ElementRef;
  eps_log_path =
    environment.epsUrl + "download-file?file_url=" + environment.eps_log_path;
  // Sample data array
  selectedColumnsMap: { [key: string]: boolean } = {};
  selectedData: any[];
  renderer: any;
  selectedColumns: string[];
  totalCount: number;
  limit: number = 10;
  offset: number = 0;
  loading:boolean = false;
  // Status mapping object
  // Status mapping object with color codes
  statusMap = {
    "-1": { label: "Invalid", color: "#FF4A4A" },
    "1": { label: "Valid", color: "#81A2FF" },
    "2": { label: "Accepted", color: "#0FEB03" },
    "-2": { label: "Accept Invalid", color: "#E3DB05" },
    "0": { label: "Uploaded", color: "#AAAAAA" },
    "-3": { label: "Rejected", color: "#FF4A4A" },
    "4": { label: "Accept Progress", color: "#FF8A3E" },
    "3": { label: "Validating", color: "#ACA710" },
  };

  // All available columns
  columns1 = [
    "s_no", // Serial Number
    "id", // Unique identifier for the upload
    "file_name", // Name of the uploaded file
    "year", // Year of upload
    "add_count", // Number of new records added
    "delete_count", // Number of records deleted
    "existing_count", // Number of existing records
    "update_count", // Number of records updated
    "status", // Current status of the upload
    "added_date", // Date the file was uploaded
    "added_by", // User ID of the uploader
    "updated_by", // User ID of the person who last updated the data
    "updated_date", // Date when the data was last updated
    "validate_end_date", // Date validation ended
    "accept_start", // Start date of acceptance
    "accept_end", // End date of acceptance
    "log", // Column for log-related actions
    "action", // Column for performing actions on the uploaded data
  ];

  // Default selected columns in the required order
  defaultSelectedColumns = [
    // 's_no',           // Serial Number
    "id", // Unique identifier for the upload
    "file_name", // Name of the uploaded file
    "added_by", // User ID of the uploader
    "added_date", // Date the file was uploaded
    "existing_count", // Number of existing records
    "update_count", // Number of records updated
    "status", // Current status of the upload
    "log", // Column for log-related actions
    "action", // Column for performing actions on the uploaded data
  ];

  // Buttons configuration
  buttonConfig = {
    log: [
      {
        label: "Validate Log",
        action: (element) => this.validateLog(element),
        style: {
          hover: {
            "background-color": "#e0e8ff",
          },
          disabled: {
            color: "gray",
            "background-color": "#f0f0f0",
          },
        },
        class: "log-button",
      },
      {
        label: "Accept Log",
        action: (element) => this.acceptLog(element),
        style: {
          hover: {
            "background-color": "#d0ffd0",
          },
          disabled: {
            color: "darkgray",
            "background-color": "#e0e0e0",
          },
        },
        class: "log-button",
      },
      {
        label: "Error Log",
        action: (element) => this.errorLog(element),
        style: {
          hover: {
            "background-color": "#d0ffd0",
          },
          disabled: {
            color: "darkgray",
            "background-color": "#e0e0e0",
          },
        },
        class: "log-button",
      },
      {
        label: "Accept Error",
        action: (element) => this.errorAcceptLog(element),
        style: {
          hover: {
            "background-color": "#d0ffd0",
          },
          disabled: {
            color: "darkgray",
            "background-color": "#e0e0e0",
          },
        },
        class: "log-button",
      },
    ],
    action: [
      {
        label: "Accept",
        action: (element) => this.accept(element),
        style: {
          border: "1px solid #008000",
          color: "green",
          padding: "5px 10px",
          width: "100px",
          "background-color": "#eaffea",
          hover: {
            "background-color": "#d9ffd9",
          },
          disabled: {
            color: "#808080",
            background: "red",
          },
        },
        // disabled: (element) => element.status !== '1', // Disable if status is not "Valid"
      },
      {
        label: "Reject",
        action: (element) => this.reject(element),
        style: {
          border: "1px solid #ff0000",
          color: "red",
          padding: "5px 10px",
          width: "100px",
          "background-color": "#ffeaea",
          hover: {
            "background-color": "#ffdada",
          },
          disabled: {
            color: "darkred",
            "background-color": "#e0e0e0",
          },
        },
        // disabled: (element) => element.status !== '1', // Disable if status is not "Valid"
      },
    ],
  };
  constructor(
    private menus: AdminMenusService,
    private api: ApiserviceService
  ) {
    // this.menus.setActive("observer-adm-upload");
  }
  ngOnInit(): void {
    this.getObserverAdmData();
    this.columns1.forEach((column) => {
      this.selectedColumnsMap[column] =
        this.defaultSelectedColumns.includes(column);
    });

    // Update selectedColumns initially
    this.updateSelectedColumns();
  }

  // Update selectedColumns based on selectedColumnsMap
  updateSelectedColumns(): void {
    // Get selected columns in the order of columns1 (not just defaultSelectedColumns)
    this.selectedColumns = this.columns1.filter(
      (column) => this.selectedColumnsMap[column]
    );
  }

  ngAfterViewInit(): void {
    // Listen for clicks on the document
    this.renderer.listen("document", "click", (event: MouseEvent) => {
      if (
        this.menuContainer &&
        !this.menuContainer.nativeElement.contains(event.target)
      ) {
        this.menuTrigger.closeMenu();
      }
    });
  }

  isColumnDisabled(column: string): boolean {
    // List of columns that should be disabled
    const disabledColumns = [
      "upload_id",
      "file_name",
      "added_by",
      "status",
      "log",
      "action",
    ];

    return disabledColumns.includes(column);
  }
  // Define the actions
  validateLog(element) {
    this.downloadLog(`_validate_${element.id}.log`);
  }

  acceptLog(element) {
    this.downloadLog(`_accept_${element.id}.log`);
  }
  errorAcceptLog(element) {
    this.downloadLog(`_accept_${element.id}_err.log`);
  }
  errorLog(element) {
    this.downloadLog(`_validate_${element.id}_err.log`);
  }
  accept(element) {
    console.log(element);
    let action_type = "accept";
    let url = `unit-adm-accept-reject?upload_id=${element.id}&user_id=${this.api.user_id}&action_type=${action_type}`;
    this.api.getEpsData(url).subscribe({
      next: (res) => {
        console.log(res);
        this.getObserverAdmData()
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  reject(element) {
    let action_type = "reject";
    let url = `unit-adm-accept-reject?upload_id=${element.id}&user_id=${this.api.user_id}&action_type=${action_type}`;
    this.api.getEpsData(url).subscribe({
      next: (res) => {
        console.log(res);
        this.getObserverAdmData()
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  stopProcess(element) {
    console.log("Stopping Process", element);
  }

  getObserverAdmData() {
    let url = `unit-adm?layer=observer&limit=${this.limit}&offset=${this.offset}&is_count=true`;
   this.loading = true
    this.api.getEpsData(url).subscribe({
      next: (res: any) => {
        this.loading = false;
        console.log(res);
        this.selectedData = res.data;
        //  if(this.offset = 0){
        this.totalCount = res.totalRecord;
        //  }
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
  pageChangeAction(event) {
    console.log(event);
    this.limit = event.limit;
    this.offset = event.offset;
    this.getObserverAdmData();
  }
  AfterUploadFile() {
    this.limit = 10;
    this.offset = 0;
    this.getObserverAdmData();
  }
  /**
   * log file is downloading here
   * @param log_url
   */
  downloadLog(type) {
    let log_url = `${
      this.eps_log_path
    }${this.api.city_code.toLocaleLowerCase()}${type}`;
    window.open(log_url.toString(), "_blank");
  }
}
